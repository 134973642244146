

import React, { Suspense } from "react";


/**
 * 
 * "component": {
    "url": "http://localhost:8085/remoteEntry.js",
    "scope": "curdComponent",
    "name": "./Widget",
    "props": {
        "props1": "propsvalue"
    }
    }
 * 
 */

import { useDynamicScript, loadComponent } from "../../utils/script.utils";




/**
 * Expect a component of above structure , language 
 * 
 * @param {*} param0 
 * @returns 
 */
export const ComponentWrapper = ({ component, language, t }) => {

    /**
     * 
     * 
     * @param {*} props 
     * @returns 
     */
    const Module = (props) => {

        const { ready, failed } = useDynamicScript({
            url: props.module && props.module.url,
            component,
        });

        if (!props.module) {
            return <h2>No module specified</h2>;
        }

        if (!ready) {
            return <Skeleton />;
        }

        if (failed) {
            throw new DOMException("Failed to load script");
        }

        const Component = React.lazy(
            loadComponent(props.module.scope, props.module.name)
        );

        return (
            <React.Suspense fallback={<Skeleton caption="Loading your application " />}>
                <Component
                    {...component.props}
                // t={t}
                // store={store}
                />
            </React.Suspense>
        );
    };

    /**
     * 
     */
    if (component && component.name && component.url && component.scope) {
        return (
            <div className="component-wrapper animate_animated animate_fadeIn">
                <Module
                    module={{
                        name: component.name,
                        url: component.url,
                        scope: component.scope,
                        props: component.props,
                    }}
                />
            </div>
        );
    } else {
        return <Skeleton />;
    }
};


function Skeleton({ caption = "Hello there 👋 | Initializing account .. " }) {

    return (<div class="skeleton-card">
        <h3 class="loading-animation-text">{caption}</h3>

        <div class="skeleton"></div>

        <div class="skeleton"></div>

        <div class="skeleton"></div>

        <div class="skeleton"></div>

        <div class="skeleton"></div>
    </div>)
}