/**
 * 
 * Component Loader is ins
 * 
 */


import React, { Suspense, useState, useEffect } from "react";

// import { BrowserRouter } from "react-router-dom";

import "./index.css";

import { ComponentWrapper } from "./components/component-wrapper/component-wrapper";



const App = () => {

  // Configuration to store the env of the application
  const [configuration, setConfiguration] = useState({});

  useEffect(() => {

    loadConfiguration();

  }, [])

  /**
   * Load the configuration 
   */
  async function loadConfiguration() {


    let component = {
    }

    // let domain = "ulbr1uat";
    let domain = window.location.host.split(".")[0];

    // const result = await fetch(`https://hgrntky25ejsmw3zobq3u6lk3q0tploo.lambda-url.ap-northeast-1.on.aws/?domain=${domain}`).then((result) => {
    //   return result.json();
    // })

    const result = await fetch(`https://applications.onedesk.app/api/data?domain=${domain}`).then((result) => {
      return result.json();
    })


    if (result.body && result.body.length && result.body[0].other_details) {

      let appConfiguration = JSON.parse(result.body[0].other_details);

      console.log(appConfiguration);

      component = {
        // "url": "https://qms-soxo.web.app/remoteEntry.js",
        // "url": "http://localhost:3000/remoteEntry.js",
        "url": appConfiguration.REACT_APP_URL,
        "scope": "Application",
        "name": "./Widget",
        "props": {
          "props1": "propsvalue"
        }
      }


      console.log("Starting application with ", appConfiguration);

      window.process = {
        env: appConfiguration
      }

      // Setting the title
      document.title = appConfiguration.REACT_APP_WEBSITE_NAME

      setConfiguration({
        ...component,
      });

    }
  }

  return (
    <Suspense fallback={<div>Loading now...</div>}>

      {/* Component Wrapper */}
      <ComponentWrapper component={configuration} {...configuration} />
      {/* Component Wrapper Ends */}

    </Suspense>
  )
};

export default App;


