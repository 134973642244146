import React, { Suspense } from "react";

/**
 * Load the webpack component
 * 
 * @param {*} scope 
 * @param {*} module 
 * @returns 
 */
export function loadComponent(scope, module) {
    return async () => {
        // Initializes the share scope. This fills it with known provided modules from this build and all remotes
        await __webpack_init_sharing__("default");
        const container = window[scope]; // or get the container somewhere else
        // Initialize the container, it may provide shared modules
        if (container) {
            await container.init(__webpack_share_scopes__.default);
            const factory = await window[scope].get(module);
            const Module = factory();
            return Module;
        } else {
            throw new DOMException("Invalid component");
        }
    };
}

/**
 * Inject the style sheets dynamically
 * 
 * @param {*} url 
 * @returns 
 */
export const injectLinks = function (url) {
    return new Promise((resolve, reject) => {
        const element = document.createElement("link");

        element.href = url;
        element.type = "text/css";
        element.rel = "stylesheet";

        element.onload = () => {
            console.log(`Dynamic Link Loaded: ${url}`);
            resolve(true);
        };

        element.onerror = () => {
            console.error(`Dynamic Link Error: ${url}`);
            document.head.removeChild(element);
            reject({ uri: url });
        };

        document.head.appendChild(element);
    });
};

/**
 * Inject scripts to Html 
 * 
 * @param {*} url 
 * @param {*} id 
 * @returns 
 */
export const useDynamicScript = (args) => {
  const [ready, setReady] = React.useState(false);
  const [failed, setFailed] = React.useState(false);

  React.useEffect(() => {
    if (!args.url) {
      return;
    }

    const element = document.createElement("script");

    element.src = args.url;
    element.type = "text/javascript";
    element.async = false;

    setReady(false);
    setFailed(false);

    element.onload = () => {
      console.log(`Dynamic Script Loaded: ${args.url}`);
      setReady(true);
    };

    element.onerror = () => {
      console.error(`Dynamic Script Error: ${args.url}`);
      setReady(true);
      setFailed(true);
    };

    document.head.appendChild(element);

    return () => {
      console.log(`Dynamic Script Removed: ${args.url}`);
      document.head.removeChild(element);
      window[args.component.scope] = undefined;
    };
  }, [args.url]);

  return {
    ready,
    failed,
  };
};

/**
 * Remove the scripts
 * 
 * @param {*} id 
 * @returns 
 */
export const removeScripts = (id) => {
    return new Promise((resolve, reject) => {
        if (!id) {
            reject("Invalid id");
        }
        const scripts = document.querySelectorAll(
            'script[data-configuration-id]:not([data-configuration-id="' + id + '"]'
        );
        if (scripts.length > 0) {
            const list = Array.from(scripts);
            list.forEach((element) => {
                document.head.removeChild(element);
            });
        }

        resolve(true);
    });
};